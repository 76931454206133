
import moment from 'moment'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mask } from 'vue-the-mask'
@Component({
  directives: { mask },
})
export default class DateSelect extends Vue {
  @Prop({ default: null }) readonly value!: string | null
  @Prop({ default: '' }) readonly label!: string | Record<string, any>
  @Prop({ default: false, type: Boolean }) readonly clearable!: boolean

  menu = false
  textDate: string | null = null
  errorMessages: string[] = []

  @Watch('value', { immediate: true })
  onChangeValue(value: string | null) {
    this.textDate = value
  }

  get clearIcon() {
    return this.model ? 'clear' : null
  }
  get model() {
    return this.value
  }
  set model(value) {
    this.errorMessages = []
    this.$emit('input', value)
    this.$emit('change', value)
  }
  onChange() {
    if (!this.textDate) {
      this.model = null
    } else if (!moment(String(this.textDate)).isValid()) {
      this.errorMessages.push('日付として読み取れませんでした。')
    } else {
      this.model = this.textDate
    }
  }
}
