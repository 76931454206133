
import { StateChanger } from 'vue-infinite-loading'
import { Vue, Component, Watch } from 'vue-property-decorator'
import { GoodsPurchase } from '@/types'
import FilterUtil from '@/filter-util'
import CSVExport from '@/components/CSVExport.vue'
import DateSelect from '@/components/DateSelect.vue'

const CSVHeaders = [
  { key: 'id', label: 'id' },
  { key: 'date', label: '日付' },
  { key: 'shopCode', label: '店舗コード' },
  { key: 'shopName', label: '店舗' },
  { key: 'goods', label: 'Goods ID' },
  { key: 'goodsName', label: 'Goods' },
  { key: 'unitPrice', label: '単価' },
  { key: 'quantity', label: '数量' },
  { key: 'customerCode', label: '顧客番号' },
  { key: 'customerName', label: '顧客' },
]

const filterUtil = new FilterUtil({
  // search: { type: String, default: null },
  ordering: { type: String, default: '' },
  dateFrom: { type: String, default: null },
  dateTo: { type: String, default: null },
  shop: { type: String, default: null },
})

@Component({
  components: { CSVExport, DateSelect },
})
export default class GoodsPurchaseListPage extends Vue {
  items: GoodsPurchase[] = []
  totalCount = 0
  next: null | string = null
  query: Record<string, any> = {}
  filter = {
    // search: null,
    ordering: '',
    dateFrom: null,
    dateTo: null,
    shop: null,
  }
  get identifier() {
    return JSON.stringify(this.query)
  }
  get fetchParams() {
    let params = {}
    if (this.query) Object.assign(params, this.query)

    return params
  }
  get csvHeaders() {
    return CSVHeaders
  }

  @Watch('$route.query', { immediate: true })
  onRouteQueryChanged(query: any) {
    this.query = filterUtil.parseQueryDict(query)
  }

  @Watch('identifier')
  onIdentifierChanged() {
    this.items = []
    this.next = null
    this.getTotalCount()
  }

  created() {
    Object.assign(this.filter, this.query)
    this.getTotalCount()
  }

  async getTotalCount() {
    this.totalCount = await this.$api
      .purchaseHistories()
      .count(this.fetchParams)
  }

  async fetchData($state: StateChanger) {
    try {
      let { results, next } = await (this.next
        ? this.$api.http.get(this.next)
        : this.$api.purchaseHistories().list(this.fetchParams))
      this.items = this.items.concat(results)
      this.next = next
      if (this.items.length) $state.loaded()
      if (!this.next) $state.complete()
    } catch (err) {
      $state.error()
    }
  }

  updateQuery() {
    let query = filterUtil.mergeQueryDict(this.query, this.filter)
    this.$router.push({ query })
  }
}
